import axios from "axios";
import { baseUrl } from "@/main";

export const accountService = {
  getByUserId: (id) => axios.get(`${baseUrl}/accounts?userId=${id}`),
  getByCustomerId: (id) => axios.get(`${baseUrl}/accounts/byCustomerId/${id}`),
  getCurrencies: () => axios.get(`${baseUrl}/collections/currencies`),
  getAccountsLinkAccountHandle: () =>
    axios.get(baseUrl + "/accounts/linkAccountHandle"),
  linkAccount: (customerId) =>
    axios.get(`${baseUrl}/accounts/linkAccountViaCustomerId/${customerId}`),
};
